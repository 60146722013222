<template>
    <BT-Blades
        v-if="currentLocation != null"
        :maximizedCount="20"
        :anchorBlades="[{ bladeName: 'pointers' }, { bladeName: 'map' }]">
        <template v-slot="bladesData">
            <BT-Blade-List
                @select="selectPointer"
                :bladesData="bladesData"
                bladeName="pointers"
                :canAdd="false"
                :canClose="false"
                dense
                :getParams="() => { return { includeDetails: true } }"
                navigation="fulfiller-pointers"
                :onPullSuccessAsync="pullPointers"
                :searchProps="['weekdays']"
                title="Pointers">
                <template v-slot:toolbar-right="{ allItems }">
                    <BT-Menu
                        icon="mdi-plus"
                        :offset-x="false"
                        small
                        title="New Pointer">
                        <template v-slot>
                            <v-list-item @click.stop="addPointer(allItems, false)">
                                <v-list-item-content>New Self Pointer</v-list-item-content>
                            </v-list-item>
                            <v-list-item @click.stop="addPointer(allItems, true)">
                                <v-list-item-content>New 3PL Pointer</v-list-item-content>
                            </v-list-item>
                        </template>
                    </BT-Menu>
                    <v-btn small icon @click="hideAllPointers(allItems)" title="Hide All">
                        <v-icon small>mdi-circle-off-outline</v-icon>
                    </v-btn>
                </template>
                <template v-slot="{ item, deleteItem, allItems }">
                    <v-list-item-avatar class="mx-0 px-0">
                        <v-slide-x-transition hide-on-leave>
                            <v-btn v-if="item.id == null || $BlitzIt.tracker.isChanged(item)" icon @click.stop="savePointer(item)">
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                            <v-btn v-else small icon @click.stop="toggleSelected(item)" title="Show Area">
                                <v-icon small :color="item.showArea && containsWeekday(item.weekdays, currentWeekday) ? item.color : 'grey'">mdi-circle</v-icon>
                            </v-btn>
                        </v-slide-x-transition>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-subtitle>
                            <v-text-field 
                                v-if="currentPointer === item"
                                @click.stop
                                hide-details
                                single-line
                                v-model="item.pointerName" 
                                label="Pointer Name" />
                            <span v-else>{{ item.pointerName || '(Pointer Name)' }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <span v-if="item.fulfillerAgreementID != null">
                                via
                                <BT-Entity
                                    v-if="item.fulfillerAgreementID != null"
                                    :itemValue="item.fulfillerAgreementID"
                                    itemText="seller.companyName"
                                    navigation="couriers-fulfilling" />
                            </span>
                            <span v-else>Self Fulfill</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <BT-Chips-Menu
                                v-model="item.weekdays"
                                :canOpen="currentPointer === item"
                                column
                                isEditing
                                :items="weekdayOptions()"
                                multiple
                                returnCSV
                                xSmall />
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-row class="ma-0 pa-0">
                            <v-btn v-if="currentPointer === item && item.boundary != null" small icon @click.stop="isMatching = true" title="Match Area">
                                <v-icon small>mdi-vector-selection</v-icon>
                            </v-btn>
                            <v-btn v-if="item.id == null" small icon title="Delete" @click.stop="removePointer(item, allItems)">
                                <v-icon small class="error--text">mdi-delete</v-icon>
                            </v-btn>
                            <v-btn v-else small icon @click.stop="deleteItem(item)" title="Delete">
                                <v-icon small class="error--text">mdi-delete</v-icon>
                            </v-btn>
                        </v-row>
                    </v-list-item-action>
                </template>
            </BT-Blade-List>
           
            <BT-Blade
                :bladesData="bladesData"
                bladeName="map"
                :canPin="false"
                :canMinimize="false"
                :canRefresh="false"
                flexColumn
                :loadingMsg="loadingMsg"
                title="Coverage">
                <template v-slot:toolbar-left>
                    <BT-Chips
                        v-model="currentWeekday"
                        class="ml-4"
                        isEditing
                        :items="weekdayOptions()"
                        returnCSV />
                </template>
               
                <template>
                    <GmapMap
                        :center="currentLocation"
                        :zoom="7"
                        style="width: 100%; height: 75vh;"
                        :options="{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: true,
                            disableDefaultUi: false }">

                        <!-- supply pointer areas -->
                        <gmap-polygon
                            v-for="(pointer, index) in filteredPointers"
                            @click="selectPointer(pointer)"
                            @paths_changed="($event) => { updatePointerPaths($event, pointer) }"
                            clickable
                            :editable="currentPointer != null && currentPointer._btID == pointer._btID"
                            :fillOpacity="0.5"
                            :key="index + 'a'"
                            :paths="pointer.boundary"
                            :strokeColor="pointer.color"
                            :strokeWeight="2" />

                        <!-- <gmap-marker
                            v-if="currentPointer != null && showPointers && containsWeekday(currentPointer.weekdays, currentWeekday)"
                            clickable
                            @click="selectSimpleLocation(currentPointer.location)"
                            :icon="{ anchor: { x: 13, y: 23 }, path: pinSVG(), fillOpacity: 1, fillColor: currentPointer.color }"
                            key="1c"
                            :position="currentPointer.location" /> -->

                    </GmapMap>
                    
                </template>
            </BT-Blade>
        </template>
    </BT-Blades>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Fulfiller-Pointers-Hub',
    components: {
        BTChips: () => import('~components/BT-Chips.vue'),
        BTChipsMenu: () => import('~components/BT-Chips-Menu.vue'),
        BTMenu: () => import('~components/BT-Menu.vue')
    },
    data: function() {
        return {
            colorSupply: '#0b5394',
            currentLocation: null,
            currentPointer: null,
            currentWeekday: 'Always',
            infoPosition: null,
            isMatching: false,
            loadingMsg: null,
            myLocations: [],
            pointers: [],
            selectedInfo: null, //From, To, Object
            showMyLocations: true,
            showPointers: true,
            // weekdayOptions: ['Always', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            windowOptions: {
                
            }
        }
    },
    computed: {
        filteredPointers() {
            return this.pointers.filter(z => this.containsWeekday(z.weekdays, this.currentWeekday) && z.showArea && z.boundary != null);
        }
    },
    async mounted() {
        if (this.currentLocation == null) {
            if (this.currentLocation == null) {
                this.currentLocation = await this.$selectItem({
                    navigation: 'locations', 
                    itemText: 'locationName', 
                    required: true });
            }
        }
    },
    methods: {
        addMatch(boundary) {
            this.isMatching = false;

            if (boundary == null) {
                return;
            }

            var copiedBoundary = [...boundary];

            if (this.currentPointer != null) {
                if (this.currentPointer.boundary != null) {
                    this.currentPointer.boundary = copiedBoundary;
                }
            }
        },
        async addPointer(pointers, use3pl) {
            var newPointer = {
                boundary: this.getAreaAround(this.currentLocation, 0.5),
                color: this.colorSupply,
                destinationLocationID: null,
                fulfillerAgreementID: null,
                id: null,
                pointerName: null,
                rowVersion: null,
                weekdays: this.currentWeekday
            }

            if (use3pl) {
                var fulfiller = await this.$selectItem({
                    navigation: 'couriers-fulfilling',
                    itemText: 'seller.companyName',
                    label: 'Fulfillment Provider'
                });

                if (fulfiller == null) {
                    return;
                }

                newPointer.fulfillerAgreementID = fulfiller.id;
            }

            newPointer = this.$BlitzIt.tracker.track(newPointer, {
                trackProps: ['id', 'rowVersion', 'boundary', 'pointerName', 'weekdays'],
                assignIgnored: { showArea: true }
            })

            pointers.unshift(newPointer);

            this.currentPointer = newPointer;
        },

        filterPointers(pointers) {
            pointers.sort(firstBy(y => y.destinationLocationID != null).thenBy(z => !this.containsWeekday(z.weekdays, this.currentWeekday)));
            return pointers;
        },

        hideAllPointers(pointers) {
            pointers.forEach(s => {
                s.showArea = false;
            })
        },
        async pullPointers(pointers) {
            //this.supplyPointers = pointers;

            this.pointers = pointers.map(pointer => {
                //var e = ind < 11 ? ind : 1;

                return this.$BlitzIt.tracker.track(pointer, {
                    trackProps: ['id', 'rowVersion', 'boundary', 'weekdays', 'pointerName'],
                    assignIgnored: { showArea: true, color: this.colorSupply } //this.colors[e] }
                })
            });

            return this.pointers;
        },
        removePointer(item, allItems) {
            if (item.id == null) {
                var ind = allItems.findIndex(z => z._btID === item._btID);
                if (ind >= 0) {
                    allItems.splice(ind, 1);
                }
                
                // ind = this.ointers.findIndex(z => z._btID === item._btID);
                // if (ind >= 0) {
                //     this.supplyPointers.splice(ind, 1);
                // }
            }
        },
        
        async savePointer(pointer) {
            try {
                this.loadingMsg = 'Saving Supply Pointer';
                var res = null;

                if (pointer.id == null) {
                    //post
                    res = await this.$BlitzIt.store.post('fulfiller-pointers', pointer);
                }
                else {
                    //patch
                    res = await this.$BlitzIt.store.patch('fulfiller-pointers', pointer);
                }

                pointer.id = res.id;
                pointer.rowVersion = res.rowVersion;

                this.$BlitzIt.tracker.reset(pointer);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        
        selectPointer(supplyPointer) {
            if (this.isMatching) {
                this.addMatch(supplyPointer.boundary);
            }
            else {
                this.currentPointer = supplyPointer;
                
                if (!this.containsWeekday(supplyPointer.weekdays, this.currentWeekday)) {
                    var s = supplyPointer.weekdays.split(',');
                    if (this.isLengthyArray(s)) {
                        this.currentWeekday = s[0];
                    }
                }

                this.infoPosition = supplyPointer.location;
                this.selectedInfo = supplyPointer;
                this.windowOptions = {
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                }
            }
        },
        selectSimpleLocation(l) {
            this.infoPosition = l;
            this.selectedInfo = l;
            this.windowOptions = {
                pixeloffset: {
                    width: 0,
                    height: -35
                }
            }
        },
        toggleSelected(item) {
            item.showArea = !item.showArea;
        },
        updatePointerPaths(mvcArray, zone) {
            let paths = [];
            for (let i=0; i<mvcArray.getLength(); i++) {
              let path = [];
              for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
                let point = mvcArray.getAt(i).getAt(j);
                path.push({lat: point.lat(), lng: point.lng()});
              }
              paths.push(path);
            }
            zone.boundary = paths[0];
        },
    }
}
</script>